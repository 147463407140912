import React from 'react';
import loadable from '@loadable/component';

const bannerLookup = {
  "LIght_Family_Banner_Homepage": loadable(() => import("./lightFamilyHomepage")),
  "BundleNSave": loadable(() => import('./bns')),
  'EverydayToteYellow': loadable(() => import('./half_banner')),
  'bns2023': loadable(() => import('./bns2023')),
  'blackfriday': loadable(() => import('./blackfriday')),
  'cybermonday': loadable(() => import('./cybermonday')),
  'brownrange': loadable(() => import('./brownrange')),
  'boxingday': loadable(() => import('./boxingday')),
  'boxingday_endssoon': loadable(() => import('./boxingday-endingsoon')),
  'trunks': loadable(() => import('./trunks')),
  'homepage_trunks': loadable(() => import('./homepage-trunks')),
  'CareCollectionHP': loadable(() => import('./care-collection-hp')),
  'CareCollectionLP': loadable(() => import('./care-collection-lp')),
  'EverydayBags': loadable(() => import('./everyday-small-bags')),
  'Intransit': loadable(() => import('./intransit')),
  'JuiletteFeatureDetails': loadable(() => import('./juiletteFeatureDetails')),
  'WeekenderDeal': loadable(() => import('./weekenderDeal')),
  'FreePersBanner': loadable(() => import('./freepersbanner')),
  'EverydayLeather': loadable(() => import('./everyday-leather')),
  'MothersDayHP': loadable(() => import('./mothers-day-hp')),
  'FreePersHalfBanner': loadable(() => import('./free-pers-half-banner')),
  'FreeExpressShipping': loadable(() => import('./free-express-shipping')),
  'EverydayResort': loadable(() => import('./everyday-resort')),
  'OlympicHP': loadable(() => import('./olympics-hp')),
  'OlympicTeam': loadable(() => import('./olympics-team')),
  'lilac': loadable(() => import('./lilacDetails')),
  'sage': loadable(() => import('./toffDetails')),
};

const CustomBannerRenderer = ({ data, locale }) => {
  const RenderBlock = bannerLookup[data.bannername] || (() => <div>Banner not found</div>);
  return <RenderBlock locale={locale} />;
};

export default CustomBannerRenderer;
