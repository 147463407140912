import { useState, useEffect } from 'react';
import { useShopStoreIncontext } from '../context/shopContext';

const useFavoriteProducts = () => {
  const [favoriteProducts, setFavoriteProducts] = useState([]);

  const { analytics } = useShopStoreIncontext((state) => {
    return {
      analytics: state.analytics,
    }
  })

  useEffect(() => {
    const favorites = JSON.parse(localStorage.getItem('wishlist')) || [];
    setFavoriteProducts(favorites);
  }, []);

  const removeFavoriteProduct = (product) => {
    const { productId } = product
    const updatedFavorites = favoriteProducts.filter(item => item !== productId);
    setFavoriteProducts(updatedFavorites);
    const favorites = JSON.parse(localStorage.getItem('wishlist')) || [];
    localStorage.setItem('wishlist', JSON.stringify(favorites.filter(item => item !== productId)));

    analytics.track('Removed from Wishlist', product);
  };

  const addFavoriteProduct = (product) => {
    const { productId } = product
    if (!favoriteProducts.includes(productId)) {
      const updatedFavorites = [...favoriteProducts, productId];
      setFavoriteProducts(updatedFavorites);
      const favorites = JSON.parse(localStorage.getItem('wishlist')) || [];
      localStorage.setItem('wishlist', JSON.stringify([...favorites, productId]));

      analytics.track('Added to Wishlist', product);
    }
  };

  return [favoriteProducts, addFavoriteProduct, removeFavoriteProduct];
};

export default useFavoriteProducts;
