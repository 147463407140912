import styled from '@emotion/styled'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React, { Fragment } from 'react'
import Video from '../Video'


const Main = styled.div`
  @media (min-width: 1025px) {
    display: flex;
    justify-content: center;
  }
`
const MainVideo = styled(Video)`
  width: 50%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  @media (max-width: 768px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
  }
`

const CopyHeader = styled.div`
  font-size: 38px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -0.44px;
  text-align: center;
  color: ${(props) => (props.textColor ? `${props.textColor};` : 'black')};;
  padding: 24px 42px;
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 16px;
  font-weight: ${(props) => (props.FontWeight ? `${props.FontWeight};` : '400')};
  font-family: ${(props) => (props.Font ? `${props.Font};` : 'Objectivity')};
  font-size: ${(props) => (props.FontSize ? ` ${props.FontSize}px;` : '32px')};
  font-weight: 400;
  font-style: italic;
  @media (max-width: 1025px) {
    padding: 32px 0px;
    font-size: 16px;
    line-height: 1.38;
  }
`
const SectionImage = styled(GatsbyImage)`
  width: ${({long}) => long ? '1300px': '650px'};
  object-fit: cover;
`
const ImagesContainer = styled.div`
  display: flex;
  justify-content: center; 
`
const MainContainer = styled.div`
  position: relative;
  padding: 32px 32px;
  max-width: 1500px;
`

const AboveTheHeader = styled.div`
  font-family: 'SackersGothicStd-Heavy';
  text-align: center;
  color: white;
  z-index: 2;
  letter-spacing: 2px;
  font-size: 24px;
  color: black;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 0;
  }
`;

const ContainerImageText = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CenteredContainer = styled.div`
  margin: 0 auto;
`;

const MultipleImages = ({ data }) => {
  const julietteLookup = {
    'soft spacious tote': 'feature-details-item-1',
    'soft work tote': 'feature-details-item-2',
    'soft bucket tote': 'feature-details-item-3',
    'soft large tote': 'feature-details-item-4',
    'soft small tote': 'feature-details-item-5',
    'soft crossbody': 'feature-details-item-6',
  }
  const id = julietteLookup[data?.ImageText[1]?.Text]
  return (
    <Main id={id}>
      <MainContainer>
        {data?.ImageText?.length > 0 ? (
          <CenteredContainer>
            <ContainerImageText>
              {data.ImageText.map((item, index) => (
                <AboveTheHeader key={index}>{item.Text}</AboveTheHeader>
              ))}
            </ContainerImageText>
          </CenteredContainer>
        ) : null}

        <ImagesContainer>
          {data.Images?.map((val, index) => {
            return (
              <Fragment key={index}>
                {val.Image?.map((item, key) => {
                  return (
                    <>
                      {
                        item.mime === 'video/mp4' ? (
                          <MainVideo
                            key={key}
                            objectFit={true}
                            styles={{
                              width: '650px'
                            }}
                            src={item.file.publicURL}
                            alt={item?.alternativeText || item?.name}
                            objectPosition={true}

                          />
                        ) : (
                          <SectionImage
                            alt={item?.alternativeText || item?.name}
                            long={data.Images.length === 1}
                            image={withArtDirection(
                              getImage(item.file?.childImageSharp?.gatsbyImageData),
                              [
                                {
                                  media: '(max-width: 1024px)',
                                  image: getImage(item.file?.childImageSharp?.gatsbyImageDataMobile),
                                },
                              ]
                            )}
                            key={key}
                            style={{ objectFit: 'contain' }}
                          />
                        )
                      }
                    </>
                  )
                })}
              </Fragment>
            )
          })}
        </ImagesContainer> 
        {
          (data?.customHeading || data?.Heading )&& (
            <>
              {
                data?.customHeading ? (
                  <CopyHeader FontWeight={data.FontWeight} Font={data.Font} FontSize={data.FontSize} textColor={data.textColor} dangerouslySetInnerHTML={{ __html: data?.customHeading }} />
                ) : (
                  <CopyHeader FontWeight={data.FontWeight} Font={data.Font} FontSize={data.FontSize} textColor={data.textColor}>{data.Heading}</CopyHeader>
                )
              }
            </>
          )
        }

      </MainContainer >
    </Main>
  )
}
export default MultipleImages
