import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { currencyCodeLookup } from '../../../helpers/constants';
import { useShopStoreIncontext } from '../../../context/shopContext';

const STORAGE_KEY = 'lastUsedPostalCode';
const API_BASE_URL = 'https://84fbmjwzma.execute-api.ap-southeast-2.amazonaws.com';

const ArrowIcon = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width="24" 
    height="24" 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="2" 
    strokeLinecap="round" 
    strokeLinejoin="round"
    className="w-4 h-4"
  >
    <path d="M5 12h14"/>
    <path d="m12 5 7 7-7 7"/>
  </svg>
);

const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
})

const getBusinessDayRange = (minDate, maxDate) => {
  const maxDateLocal = new Date(maxDate);
  
  // Format date to "Mon Nov 6" style
  const maxFormattedDate = maxDateLocal.toLocaleDateString('en-US', { 
    weekday: 'short',
    month: 'short', 
    day: 'numeric'
  });

  const minDateLocal = new Date(minDate);
  
  // Format date to "Mon Nov 6" style
  const minFormattedDate = minDateLocal.toLocaleDateString('en-US', { 
    weekday: 'short',
    month: 'short', 
    day: 'numeric'
  });

  return `Expected between ${minFormattedDate} - ${maxFormattedDate}`;
};

const formatPrice = (cents, currency) => {
  if (cents === 0) return 'Free';
  const amount = (cents / 100).toFixed(2);
  return `${formatter.format(amount)} ${currency}`;
};

const PostalCode = ({ sku, storeLocale, background }) => {
  const { analytics } = useShopStoreIncontext((state) => {
    return {
      analytics: state.analytics,
    }
  })

  const [postalCode, setPostalCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [branchOptions, setBranchOptions] = useState([]);
  const [shippingOptions, setShippingOptions] = useState([]);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('stores');

  useEffect(() => {
    setBranchOptions([])
    setShippingOptions([])
  }, [sku])

  // Initialize postal code from localStorage after component mounts
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const savedPostalCode = localStorage.getItem(STORAGE_KEY);
      if (savedPostalCode) {
        setPostalCode(savedPostalCode);
      }
    }
  }, []);

  // Save postal code to localStorage
  useEffect(() => {
    if (typeof window !== 'undefined' && postalCode) {
      localStorage.setItem(STORAGE_KEY, postalCode);
    }
  }, [postalCode]);

  const findOptions = async () => {
    if (!postalCode.trim()) {
      setError('Please enter a postal code');
      return;
    }

    setIsLoading(true);
    setError('');
    setBranchOptions([]);
    setShippingOptions([]);

    try {
      const { data } = await axios.post(
        `${API_BASE_URL}/dev/handler/delivery-options`,
        {
          postalCode,
          items: [{ sku }],
          currency: currencyCodeLookup[storeLocale],
          locale: storeLocale,
        }
      );

      analytics.identify(undefined, {
        address: {
          postalCode: postalCode
        }
      })

      analytics.track('Viewed Shipping Options', {
        postalCode: postalCode,
        sku: sku,
        storeLocale: storeLocale,
      });

      setBranchOptions(data?.branches ?? []);
      setShippingOptions(data?.shippingOptions?.rates ?? []);
      
      if (data?.branches?.length > 0) {
        setActiveTab('stores');
      } else if (data?.shippingOptions?.rates?.length > 0) {
        setActiveTab('shipping');
      }
    } catch (err) {
      console.error('Failed to fetch shipping options:', err);
      setError(
        err.response?.data?.message || 
        'Failed to fetch shipping options. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getDirections = (mapUrl) => {
    if (typeof window !== 'undefined') {
      window.open(mapUrl, '_blank');
    }
  };

  const hasResults = branchOptions.length > 0 || shippingOptions.length > 0;

  return (
    <div className="w-full mt-4">
      <div className="mb-4">
        <div className="flex justify-end">
          <div className="flex w-fit">
            <input
              className="px-3 h-8 w-28 text-xs border border-black outline-none"
              placeholder="Postcode"
              value={postalCode}
              onChange={(e) => {
                setError('');
                setPostalCode(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  findOptions();
                }
              }}
            />
            <button
              style={{ backgroundColor: background || 'black' }}
              className={`text-white px-4 w-48 text-sm font-medium transition-opacity hover:opacity-80 ${
                isLoading ? 'cursor-not-allowed opacity-50' : ''
              }`}
              onClick={findOptions}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'Check options'}
            </button>
          </div>
        </div>
        {error && <p className="text-right text-red-600 mt-1 text-xs">{error}</p>}
      </div>

      {hasResults && (
        <div className="mt-4">
          <div className="flex border-b border-black">
            {branchOptions.length > 0 && (
              <button
                style={{ backgroundColor: activeTab === 'stores' ? (background || 'black') : 'white' }}
                className={`px-4 py-1.5 text-xs font-medium ${
                  activeTab === 'stores'
                    ? 'text-white'
                    : 'text-black'
                }`}
                onClick={() => setActiveTab('stores')}
              >
                Stores
              </button>
            )}
            {shippingOptions.length > 0 && (
              <button
                style={{ backgroundColor: activeTab === 'shipping' ? (background || 'black') : 'white' }}
                className={`px-4 py-1.5 text-xs font-medium ${
                  activeTab === 'shipping'
                    ? 'text-white'
                    : 'text-black'
                }`}
                onClick={() => setActiveTab('shipping')}
              >
                Shipping
              </button>
            )}
          </div>

          <div className="mt-3">
            <div className='text-sm mb-2'>
              Stock may vary at each store.
            </div>
            {activeTab === 'stores' && branchOptions.length > 0 && (
              <div className="space-y-2">
                {branchOptions.map((branch, index) => (
                  <div key={index} className="border border-black p-2 flex justify-between items-center">
                    <div>
                      <p className="text-xs">
                        <span className="font-medium font-semibold">{branch.name}.</span> 
                      </p>
                      <p className="text-xs"> {branch.address} </p>
                    </div>
                    {branch.mapUrl && (
                      <button
                        onClick={() => getDirections(branch.mapUrl)}
                        style={{ backgroundColor: background || 'black', minWidth: 130, marginLeft: 16 }}
                        className="flex items-center space-x-1 px-3 py-1 text-white text-xs hover:opacity-80 transition-opacity"
                      >
                        <span>Get Directions</span>
                        <ArrowIcon />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            )}

            {activeTab === 'shipping' && shippingOptions.length > 0 && (
              <div className="space-y-2">
                {shippingOptions.map((option, index) => (
                  <div key={index} className="border border-black p-2">
                    <p className="text-xs font-medium mb-1">
                      <span className='font-semibold'>{option.service_name}</span>
                    </p>
                    {option.description && (
                      <p className="text-xs">{option.description}</p>
                    )}
                    <p className="text-xs">
                      {getBusinessDayRange(option.min_delivery_date, option.max_delivery_date)}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PostalCode;