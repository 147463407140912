import { useEffect, useState } from 'react'
import fbq from '../helpers/fbq'
import twq from '../helpers/twq'
import { trackAddToCart } from '../lib/ga4'


function retryGTM(query, timout) {
  try {
    query()
  } catch (err) {
    if (!window.location.href.includes('uk')) {
      setTimeout(() => retryGTM(query, timout), timout)
    }
  }
}

function retryLearnQ(query, timout) {
  try {
    query()
  } catch (err) {
    if (!window.location.href.includes('uk')) {
      setTimeout(() => retryLearnQ(query, timout), timout)
    }
  }
}

function retryInstant(query, timout) {
  try {
    query()
  } catch (err) {
    if (!window.location.href.includes('uk')) {
      setTimeout(() => retryInstant(query, timout), timout)
    }
  }
}

const useTracking = (initialState, analytics) => {
  const [itemData, setItemData] = useState(initialState)

  useEffect(() => {
    let gtm
    if (window.google_tag_manager) {
      gtm = window.google_tag_manager['GTM-NWW88JM'];
    }

    const { title, id, price, variant, url, sku, imageURL, currency, Category, SubCategory, Range } = itemData
    if (analytics) {
      analytics.track('Product Viewed', {
        product_id: id,
        sku: sku,
        category: 'Luggage',
        name: title,
        brand: 'July',
        variant: variant,
        price: price,
        quantity: 1,
        currency: currency,
        value: price,
        url: window.location.href,
        image_url: imageURL,
        Category, SubCategory, Range
      });
    }
    if (window.ttq) {
      setTimeout(() => {
        try {
          ttq?.track('ViewContent', {
            contents: [
              {
                content_id: id,
                content_name: title,
                content_category: 'product',
                brand: 'July',
                quantity: 1,
                price: price,
                currency: currency,
              }
            ]
          })
        } catch (e) {
          console.log(e)
        }
      }, 500)
    }
    if (itemData) {
      const item = {
        ProductName: title,
        ProductID: id,
        Categories: ['Luggage'],
        URL: url,
        Brand: 'July',
        Price: price,
        CompareAtPrice: price,
        ImageURL: imageURL,
      }
      setTimeout(() => {
        var _learnq = window._learnq || []

        retryLearnQ(() => {
          _learnq.push(['track', 'Viewed Product', item])
        }, 3000)

        retryLearnQ(() => {
          _learnq.push([
            'trackViewedItem',
            {
              Title: item.ProductName,
              ItemId: item.ProductID,
              Categories: item.Categories,
              ImageUrl: item.ImageURL,
              Url: item.URL,
              Metadata: {
                Brand: item.Brand,
                Price: item.Price,
                CompareAtPrice: item.CompareAtPrice,
              },
            },
          ])
        }, 3000)

        retryInstant(() => {
          window.InstantJS.track('PRODUCT_VIEWED', [
            {
              provider: 'KLAVIYO',
              event: item,
            },
          ])
        })

        if (window.gtag) {
          window.gtag('event', 'view_item', {
            "items": [
              {
                "id": item.ProductID,
                "name": item.ProductName,
                "brand": "July",
                "variant": itemData.variant,
                "price": item.Price
              }
            ]
          });
        }
        retryGTM(() => {
          window.dataLayer.push({
            event: 'ViewContent',
            ecommerce: {
              currencyCode: 'AUD',
              detail: {
                products: [
                  {
                    ...itemData,
                  },
                ],
              },
            },
          })
        }, 3000)
        gtm?.dataLayer.reset();

        try {
          window.pintrk('track', 'pagevisit', {
            currency: currency,
            line_items: [{
              product_id: id.split('_')[1],
              variant_id: id,
              product_category: 'Luggage',
            }]
          })
        } catch (e) {
          console.log(e)
        }
      }, 500)
    }
  }, [itemData])

  const addToCart = () => {
    setTimeout(() => {
      let gtm
      if (window.google_tag_manager) {
        gtm = window.google_tag_manager['GTM-NWW88JM'];
      }

      const { title, id, price, variant, url, sku, imageURL, selectedVariant, currency, product_categories } = itemData
      const item = {
        ProductName: title,
        ProductID: id,
        Categories: ['Luggage'],
        URL: url,
        Brand: 'July',
        Price: price,
        CompareAtPrice: price,
        ImageURL: imageURL,
      }
      var _learnq = window._learnq || []

      let checkoutUrl = 'http://checkout.july.com/checkout'
      try {
        checkoutUrl = JSON.parse(localStorage.getItem('checkout_id_v2'))[1].webUrl
        checkoutUrl = checkoutUrl.replace(
          'july-travel.myshopify.com',
          'checkout.july.com'
        )
      } catch (e) {
        // Do nothing. checkout_id_v2 item not available.
      }

      try {
        fbq('AddToCart', {
          content_name: title,
          content_category: 'product',
          content_ids: [id],
          content_type: 'product',
          value: price,
          currency: currency,
          product_categories: product_categories
        })
      } catch (e) {
        console.log(e)
      }

      const addToCartEvent = {
        $value: item.Price,
        AddedItemProductName: item.ProductName,
        AddedItemProductID: item.ProductID,
        AddedItemSKU: sku,
        AddedItemCategories: item.Categories,
        AddedItemURL: url,
        AddedItemPrice: item.Price,
        AddedItemQuantity: 1,
        AddedItemImageURL: item.ImageURL,
        CheckoutURL: checkoutUrl,
        Items: [
          {
            ProductID: item.ProductID,
            SKU: sku,
            ProductName: item.ProductName,
            Quantity: 1,
            ItemPrice: item.Price,
            RowTotal: item.Price,
            ProductURL: url,
            ProductCategories: item.Categories,
            ImageUrl: item.ImageURL,
          },
        ],
      };

      retryLearnQ(() => {
        window.InstantJS.track('ITEM_ADDED_TO_CART', [
          {
            provider: 'KLAVIYO',
            event: addToCartEvent,
          },
        ])
      })

      retryLearnQ(() => {
        _learnq.push(['track', 'Added to Cart', addToCartEvent])
      }, 3000)

      gtm?.dataLayer.reset();
      retryGTM(() => {
        window.dataLayer.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: 'AUD',
            add: {
              // 'add' actionFieldObject measures.
              products: [
                {
                  //  adding a product to a shopping cart.
                  ...itemData,
                },
              ],
            },
          },
        })
        gtm?.dataLayer.reset();
      }, 3000)

      trackAddToCart({
        currency: currency,
        value: price,
        items: [{
            item_id: selectedVariant.SKU,
            item_name: title,
            item_variant: variant,
            discount: null,
            price: price,
            quantity: 1,
        }],
        sticky: false,
      })

      try {
        ttq?.track('AddToCart', {
          contents: [
            {
              content_id: id,
              content_name: title,
              content_category: product_categories,
              brand: 'July',
              quantity: 1,
              price: price,
            }
          ]
        })
      } catch (e) {
        console.log(e)
      }

      twq('tw-ompvm-omqqp', {
        contents: [
          { content_name: title },
          {
            content_id: id
          },
        ],
        value: price,
        currency: currency
      })

      try {
        window.pintrk('track', 'addtocart', {
          value: price,
          currency: currency,
          line_items: [{
            product_id: id.split('_')[1],
            variant_id: id,
            product_category: 'Luggage',
          }]
        })
      } catch (e) {
        console.log(e)
      }
    }, 2000)
  }
  return [addToCart, setItemData]
}

export default useTracking
