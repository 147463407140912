import ShopProvider from './src/context/shopContext'
import { Location } from '@reach/router'
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider } from './src/hooks/useMixpanel'
import { AnalyticsBrowser } from '@segment/analytics-next'
const React = require('react')

export const analytics = AnalyticsBrowser.load(
  {
    writeKey: 'tHd0a2HwA6rKgwRAiEi1GobR8iOQY8gU',
    cdnURL: 'https://analytics.july.com',
  },
  {
    useQueryString: true,
    integrations: {
      'Segment.io': {
        apiHost: 'analytics-api.july.com/v1',
        protocol: 'https'
      }
    }
  }
)

function getUtmParams() {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    name: urlParams.get('utm_campaign') || undefined,
    source: urlParams.get('utm_source') || undefined,
    medium: urlParams.get('utm_medium') || undefined,
    term: urlParams.get('utm_term') || undefined,
    content: urlParams.get('utm_content') || undefined,
    id: urlParams.get('utm_id') || undefined
  };
}

function getMetaCookies() {
  const cookies = document.cookie.split(';').reduce((acc, cookie) => {
    const [key, value] = cookie.trim().split('=');
    acc[key] = value;
    return acc;
  }, {});

  return {
    _fbp: cookies._fbp || undefined,
    _fbc: cookies._fbc || undefined
  };
}

function waitForMetaCookies(maxWaitTime = 1000) {
  const startTime = Date.now();
  
  return new Promise((resolve) => {
    function checkCookies() {
      const { _fbp, _fbc } = getMetaCookies();

      if (_fbp || _fbc) {
        const urlParams = new URLSearchParams(window.location.search);
        const fbclid = urlParams.get('fbclid');
        
        resolve({ 
          fbclid,
          _fbp,
          _fbc
        });
      } else if (Date.now() - startTime < maxWaitTime) {
        setTimeout(checkCookies, 50);
      } else {
        const fbclid = new URLSearchParams(window.location.search).get('fbclid');
        resolve(fbclid ? { fbclid } : {});
      }
    }

    checkCookies();
  });
}

function getIds() {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    k_id: urlParams.get('utm_klaviyo_id') || undefined,
    email: urlParams.get('e_hash') || undefined,
  };
}

function cleanObject(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
}

const mixpanelConfig = {
  apiToken: `${process.env.MIXPANEL_PRODUCT_TOKEN}`,
  enableOnDevMode: true,
  pageViews: 'all',
}

const api_host = process.env.MIXPANEL_API_BASE || 'https://api.mixpanel.com'

function isEnable(options) {
  return (
    (process.env.NODE_ENV === `production` || options.enableOnDevMode) &&
    options.apiToken
  )
}

function trackEvent(eventName, properties) {
  if (eventName) {
    const utmParams = getUtmParams();
    const metaCookies = getMetaCookies();
    
    // Always include Meta cookies in context if they exist
    const campaignData = cleanObject({ 
      ...utmParams,
      ...metaCookies
    });

    mixpanel.track(eventName, properties)
    analytics.page(undefined, undefined, campaignData)
  }
}

function getOptions(pluginOptions) {
  const defaultsOptions = {
    apiToken: null,
    enableOnDevMode: true,
    mixpanelConfig: null,
    trackPageViewsAs: null,
    getPageViewTransformerFn: 'return function(location) { return location; }'
  }

  const options = { ...defaultsOptions, ...pluginOptions }
  return { ...options, isEnable: isEnable(options) }
}

function trackPageViews(
  location,
  pageViews,
  trackPageViewsAs,
  getPageViewTransformerFn
) {
  if (pageViews && location) {
    let eventName
    if (pageViews instanceof Object) {
      eventName = pageViews[location.pathname]
    } else if (trackPageViewsAs) {
      eventName = trackPageViewsAs
    } else if (pageViews === 'all') {
      eventName = `View page ${location.pathname}`
    }

    const pageViewEventTransformerFn = new Function(
      'location',
      getPageViewTransformerFn
    )
    const event = pageViewEventTransformerFn()(location)
    trackEvent(eventName, event)
  }
}

export const wrapRootElement = (props) => {
  const { element } = props
  return (
    <MixpanelProvider>
      <Location>
        {({location}) => (
          <ShopProvider location={location} pathname={props.pathname} analytics={analytics}>
          {element}
          </ShopProvider>
        )}
      </Location>
    </MixpanelProvider>
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  const options = getOptions(mixpanelConfig)

  if (isEnable(options) && JSON.stringify({...location, state: null, key: null, action: null}) !== JSON.stringify({...prevLocation, state: null, key: null, action: null})) {
    trackPageViews(
      location,
      options.pageViews,
      options.trackPageViewsAs,
      options.getPageViewTransformerFn
    )
  }

  if (!prevLocation || prevLocation?.pathname === location.pathname) {
    return;
  }

  if (!prevLocation && !location.hash) {
    // do not restore scroll position on page refresh without hash
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }), 0);
  }
}

export const shouldUpdateScroll = ({ routerProps: { location }, prevRouterProps, getSavedScrollPosition }) => {
  const prevLocation = prevRouterProps?.location

  window.history.scrollRestoration = 'manual'
  const currentPosition = getSavedScrollPosition(location) || [0, 0]

  setTimeout(() => {
    if (location.hash) {
      // If hash (`/#something`), smooth scroll to that position
      const item = document.querySelector(`${location.hash}`)
      item?.scrollIntoView({ behavior: 'smooth' })
    } else if (prevLocation?.pathname === location.pathname) {
    } else {
      // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
      window.scrollTo({
        top: currentPosition[1],
        left: currentPosition[0],
        behavior: 'instant',
      })

    }
  }, 0)

  // do not let gatsby do anything more
  return false
}

const storeAnonymousId = async () => {
  try {
    const user = await analytics.user();
    const anonymousId = user.anonymousId();
    if (anonymousId) {
      localStorage.setItem('segment_anonymous_id', anonymousId);
      return anonymousId;
    }
  } catch (error) {
    console.error('Error storing anonymous ID:', error);
  }
  return null;
}

export const onClientEntry = async () => {
  await storeAnonymousId();
  
  // Runs when the Gatsby browser runtime first starts
  setTimeout(() => {
    // Check if URL path matches the pattern /*/faqs/ and has #contact hash
    if (window.location.pathname.match(/\/.+\/faqs\//) && window.location.hash === '#contact') {
      // Extract the wildcard part from the URL
      const wildcard = window.location.pathname.split('/')[1];
      // Redirect while keeping the wildcard part in the new URL
      window.location.replace(`/${wildcard}/contact-us/`);
    }
  }, 0);

  const options = getOptions(mixpanelConfig)
  if (!options.isEnable) {
    mixpanel.init('disable', { autotrack: false, api_host })
    mixpanel.disable()
    return
  }

  const urlParams = new URLSearchParams(window.location.search);
  const fbclid = urlParams.get('fbclid');
  
  if (fbclid) {
    const metaParams = await waitForMetaCookies();
    const utmParams = getUtmParams();
    
    if (metaParams._fbp || metaParams._fbc) {
      analytics.track('Clicked Meta Ad', {
        ...metaParams,
        ...utmParams
      });
    }
  }

  const idParams = getIds();
  const idData = cleanObject(idParams);

  if (idData?.k_id || idData?.email) {
    analytics.identify(undefined, idData)
  }

  const customOptions = Object.assign(
    { track_pageview: false, api_host },
    options.mixpanelConfig
  )

  mixpanel.init(options.apiToken, customOptions)
}